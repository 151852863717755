<template>
  <div class="test-report">
    <div class="flex flex-between">
      <h1 class="fs-20 fw-600 truncate" style="max-width: 1030px" :title="testDetails.Title">
        Test Analysis
        <span style="color: rgb(100, 44, 144)">{{
          testDetails.Title ? "/ " + testDetails.Title : ""
        }}</span>
      </h1>
      <a class="back-btn waves-effect waves-light btn getclassbutton" @click="Goback()"
        ><span class="Tiny material-icons" style="position: relative; top: 7px; margin-right: 2px"
          >arrow_back</span
        >Go Back</a
      >
    </div>
    <div class="white test-list" style="margin-top: 20px">
      <h2 class="fs-15 fw-600 truncate" style="color: #555353de; margin-bottom: 8px">
        {{ testDetails.Title }}
      </h2>
      <div class="row test-details flex items-center mb-0">
        <div class="col s12 m6 l3 p-0 column-1">
          <h2
            class="fs-14 fw-600 gray-text-clr flex items-end"
            :title="`Start Date Time: ${formatDateTime(testDetails.StartDateTime)}`"
          >
            <img :src="require(`../assets/calendar 2.svg`)" alt="" style="margin-right: 6px" />
            <span class="fw-500 truncate" style="max-width: 200px">{{
              formatDateTime(testDetails.StartDateTime)
            }}</span>
          </h2>
        </div>
        <div class="col s12 m6 l3 p-0 column-1">
          <h2 class="fs-14 fw-600 gray-text-clr flex items-end">
            <img :src="require(`../assets/books10.svg`)" alt="" style="margin-right: 6px" />
            <span class="fw-500 truncate" style="max-width: 200px">{{ testDetails.Subjects }}</span>
          </h2>
        </div>
        <div class="col s12 m6 l3 p-0 column-1">
          <h2
            class="fs-14 fw-600 gray-text-clr flex items-end"
            :title="'Question: ' + testDetails.QuestionCount"
          >
            <img :src="require(`../assets/question 2.svg`)" alt="" style="margin-right: 6px" />
            <span class="fw-500 truncate" style="max-width: 200px">{{
              testDetails.QuestionCount
            }}</span>
          </h2>
        </div>
        <div class="col s12 m6 l3 p-0 column-1">
          <h2
            class="fs-14 fw-600 gray-text-clr flex items-center"
            :title="'Duration: ' + formatDuration(testDetails.Duration)"
          >
            <img :src="require(`../assets/time-passing 1.svg`)" alt="" style="margin-right: 6px" />
            <span class="fw-500 truncate" style="max-width: 200px">{{
              formatDuration(testDetails.Duration)
            }}</span>
          </h2>
        </div>
        <div class="col s12 m6 l3 p-0 column-1">
          <h2
            class="fs-14 fw-600 gray-text-clr flex items-end"
            title="Students Appeared / Total Student"
          >
            <img :src="require(`../assets/student (2) 1.svg`)" alt="" style="margin-right: 6px" />
            <span class="fw-500 truncate" style="max-width: 200px"
              >{{ testDetails.StudentsAppeared }} / {{ testDetails.Students }}</span
            >
          </h2>
        </div>
      </div>
    </div>
    <div class="row input-box" v-if="studentTestDetails.length > 0">
      <div class="col l3 m6 s12 pl-0">
        <input type="text" v-model="searchStudentName" placeholder="Search by student name" />
      </div>
    </div>
    <div class="reports" v-if="studentTestDetails.length > 0">
      <div class="table-head">
        <table class="qtl striped" style="width: 100%">
          <thead>
            <tr style="color: #646464; margin-bottom: 0" class="row flex items-center">
              <th class="col s5 left-align">
                <a class="tooltip">
                  <img
                    :src="require(`../assets/student (2) 1.svg`)"
                    alt=""
                    style="margin-right: 5px; width: 25px"
                  />
                  <span class="tooltiptext" style="left: -21px; width: auto">Students</span>
                </a>
                <!-- Name -->
              </th>
              <th class="col s1 left-align p-0">
                <a class="tooltip">
                  <img
                    :src="require(`../assets/marks12.svg`)"
                    style="margin-left: 5px; width: 27px"
                    class="tooltip"
                  />
                  <span class="tooltiptext" style="left: -8px; width: auto">Marks</span>
                </a>
                <!-- Marks -->
              </th>
              <th class="col s1 left-align p-0">
                <a class="tooltip">
                  <img
                    :src="require(`../assets/target (1) 2.svg`)"
                    style="margin-left: 5px; width: 31px"
                    class="tooltip"
                  />
                  <span class="tooltiptext" style="left: -16px; width: auto">Attempted</span>
                </a>
                <!-- Attempted -->
              </th>
              <th class="col s1 left-align p-0">
                <a class="tooltip">
                  <img
                    :src="require(`../assets/correct12.svg`)"
                    style="margin-left: 5px; width: 25px"
                  />
                  <span class="tooltiptext" style="left: -12px; width: auto">Correct</span>
                </a>
                <!-- Correct -->
              </th>
              <th class="col s1 left-align p-0">
                <a class="tooltip">
                  <img
                    :src="require(`../assets/incorrect12.svg`)"
                    style="margin-left: 5px; width: 25px"
                  />
                  <span class="tooltiptext" style="left: -15px; width: auto">Incorrect</span>
                </a>

                <!-- Incorrect -->
              </th>
              <th class="col s1 left-align p-0">
                <a class="tooltip">
                  <!-- Skipped -->
                  <img :src="require(`../assets/skip12.svg`)" style="width: 25px" />
                  <span class="tooltiptext" style="left: -18px; width: auto">Skipped</span>
                </a>
              </th>
              <th class="col s1 left-align p-0 flex">
                <a class="tooltip">
                  <img
                    :src="require(`../assets/target (1) 1.svg`)"
                    style="margin-left: 3px; width: 33px; margin-top: 2px"
                  />
                  <span class="tooltiptext" style="left: -17px; width: auto">Accuracy%</span>
                </a>

                <!-- Accuracy% -->
              </th>
              <th class="col s1 left-align p-0" style="margin-top: 6px">
                <!-- Rank -->
                <a class="tooltip">
                  <img
                    :src="require(`../assets/rank11.svg`)"
                    style="margin-right: 10px; width: 25px"
                  />
                  <span class="tooltiptext" style="left: -11px; width: auto">Rank</span>
                </a>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="scroll-verticle-bar" style="max-height: calc(100vh - 100px)">
        <div v-for="(data, index) in finalStudentTestDetail" :key="index" class="students">
          <div
            class="white test-list"
            style="margin-top: 15px; padding: 20px 5px"
            @click="selectDetail(index)"
          >
            <div class="row test-details flex items-center mb-0">
              <div class="col s5 left-align column-1">
                <h2 class="fs-14 fw-500 gray-text-clr items-center truncate">{{ data.Name }}</h2>
              </div>
              <div class="col s1 left-align column-1">
                <h2 class="fs-14 fw-500 gray-text-clr flex items-center">{{ data.TotalMarks }}</h2>
              </div>
              <div class="col s1 left-align column-1">
                <h2 class="fs-14 fw-500 gray-text-clr flex items-center">{{ data.Attempted }}</h2>
              </div>
              <div class="col s1 left-align column-1">
                <h2 class="fs-14 fw-500 gray-text-clr flex items-center">{{ data.Correct }}</h2>
              </div>
              <div class="col s1 left-align column-1">
                <h2 class="fs-14 fw-500 gray-text-clr flex items-center">{{ data.Incorrect }}</h2>
              </div>
              <div class="col s1 left-align column-1">
                <h2 class="fs-14 fw-500 gray-text-clr flex items-center">{{ data.Skipped }}</h2>
              </div>
              <div class="col s1 column-1">
                <h2 class="fs-14 fw-500 gray-text-clr flex items-center">
                  {{ data.Accuracy !== 0 ? data.Accuracy.toFixed(0) : data.Accuracy }}
                </h2>
              </div>
              <div class="col s1 left-align column-1">
                <h2 class="fs-14 fw-500 gray-text-clr flex">{{ data.Rank }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center" style="margin-bottom: 20px">
      <img
        :src="require(`../assets/no-result-found.png`)"
        alt=""
        style="width: 200px; opacity: 0.7"
      />
      <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">
        No records available for '{{ testDetails.Title }}'.
      </p>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import moment from "moment";
import MobileAPI from "../Mobileapi";

const toast = useToast();

export default {
  data() {
    return {
      testDetails: [],
      studentTestDetails: [],
      showDetail: false,
      currentIndex: null,
      searchStudentName: "",
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  activated() {
    this.testDetails = JSON.parse(this.$route.params.testDetails);
    this.$store.dispatch("showLoader", true);
    MobileAPI.AffiliationTestAnalysisReport(this.testDetails.InstituteTestId, (response) => {
      if (response.responseCode === 200 && response.data.length > 0) {
        this.studentTestDetails = response?.data[0].sort((a, b) => Number(a.Rank) - Number(b.Rank));
      }
      if (response.responseCode === 500) {
        toast.error(`${response.data.info}`, {});
        this.studentTestDetails = [];
      }
      this.$store.dispatch("showLoader", false);
    });
  },
  methods: {
    formatDuration(duration) {
      if (duration === 90) {
        return "1 hr 30min";
      }
      if (duration === 120) {
        return "2 hrs";
      }
      if (duration === 180) {
        return "3 hrs";
      }
      return `${duration} min`;
    },
    formatTestDuration(duration) {
      if (duration >= 3600) {
        return `${(duration / 3600).toFixed(2)} hrs`;
      }
      if (duration >= 60) {
        return `${(duration / 60).toFixed(2)} min`;
      }
      return `${duration} sec`;
    },
    timeFormat(timeinsec) {
      if (timeinsec >= 3600) {
        return `${(timeinsec / 3600).toFixed(2)} hrs`;
      }
      if (timeinsec >= 60) {
        return `${(timeinsec / 60).toFixed(2)} min`;
      }
      return `${timeinsec} sec`;
    },
    selectDetail(index) {
      if (this.currentIndex === index) {
        this.currentIndex = null;
        this.showDetail = false;
      } else {
        this.currentIndex = index;
        this.showDetail = true;
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).utcOffset("-00:00").format("DD-MM-YYYY, hh:mm A");
    },
    Goback() {
      this.$router.push({ name: "ManageInstituteTest", params: { isNavigate: 1 } });
    },
  },
  computed: {
    finalStudentTestDetail() {
      if (this.searchStudentName !== "") {
        return this.studentTestDetails.filter((test) =>
          test.Name.toLowerCase().includes(this.searchStudentName.toLowerCase()),
        );
      }
      return this.studentTestDetails;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

.test-report {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box !important;
  margin-top: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

table.dtltbl tbody tr th {
  border-radius: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.gray-text-clr {
  color: #333333;
}

.back-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}
.btn:hover {
  box-shadow: none !important;
}

.test-list {
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 6px;
  padding: 12px 17px;
  border: 1px solid #e0e4f0 !important;
}
.students:first-child .test-list {
  margin-top: 0 !important;
}

.input-box {
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 6px;
  background: white;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 0;
  border: 1px solid #e0e4f0 !important;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

input[type="text"] {
  border: 1px solid #d2d2d2 !important;
  background-color: white !important;
  height: 30px;
  border-radius: 4px;
  padding: 2px 10px;
  margin: 0;
  font-size: 14px;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
}

.detail-head {
  background-color: #8a3caec9;
}
.table-head {
  position: sticky;
  position: -webkit-sticky;
  top: 147px;
  background: #f6f8fb;
  padding: 15px 5px 0;
  margin-bottom: 10px;
}
.tooltip {
  position: relative;
  cursor: auto;
}
.tooltiptext {
  visibility: hidden;
  background-color: #3fbbb9;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 3px;
  z-index: 1;
  white-space: break-spaces;
  text-align: center;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all ease;
  font-weight: 600;
  position: absolute;
  left: -15px;
  bottom: -32px;
}
.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #3fbbb9;
  left: 42%;
  top: -5px;
  -webkit-backdrop-filter: #3fbbb9;
  backdrop-filter: #3fbbb9;
  transform: rotate(45deg);
  background: #3fbbb9;
  visibility: hidden;
  transition: 0.1s all ease;
  line-height: 20px;
}
.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
}
</style>
